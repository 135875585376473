import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ColorSelection from './components/ColorSelection';
import CardList from './components/CardList'; // You need to create this component

function App() {
  return (
    <Router basename="/developers/mitzael_serna/mtg">
      <Routes>
        <Route path="/" element={<ColorSelection />} />
        <Route path="/cards/:color" element={<CardList />} />
      </Routes>
    </Router>
  );
}

export default App;
