// CardList.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './CardList.css'; // Make sure this points to your actual CSS file for styles

const Card = ({ card }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const handleImageClick = () => {
    setIsZoomed(true);
    document.addEventListener('keydown', handleEscapePress);
  };

  const handleEscapePress = (e) => {
    if (e.key === 'Escape') {
      closeZoom();
    }
  };

  const handleOverlayClick = () => {
    closeZoom();
  };

  const closeZoom = () => {
    setIsZoomed(false);
    document.removeEventListener('keydown', handleEscapePress);
  };

  return (
    <div className="card">
      <h4>{card.name}</h4>
      <img
        src={card.imageUrl}
        alt={card.name}
        onClick={handleImageClick}
        style={{ cursor: 'pointer' }}
      />
      {isZoomed && (
        <div className="overlay" onClick={handleOverlayClick}>
          <img src={card.imageUrl} alt={card.name} className="zoomed-image" />
        </div>
      )}
    </div>
  );
};

function CardList() {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalCardsCount, setTotalCardsCount] = useState(0);
  const [rowCount, setRowCount] = useState(3); // Initial number of rows to display
  const [lastCard, setLastCard] = useState(0); // Last card loaded in the list
  const cardsPerRow = 27; // Number of cards per row
  const navigate = useNavigate();
  const { color } = useParams();

  useEffect(() => {
    setLoading(true);
    // Fetch the initial set of cards
    const fetchCards = async () => {
      try {
        const response = await fetch(`./${color}.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
        const cardNames = await response.json();
        setTotalCardsCount(cardNames.length); // Set the total number of cards
        return cardNames;
      } catch (err) {
        setError(`Error fetching cards: ${err.message}`);
        return [];
      }
    };

    fetchCards().then(cardNames => {
      const visibleCardNames = cardNames.slice(lastCard, cardsPerRow + lastCard);
      loadCardImages(visibleCardNames);
      setLastCard(lastCard + cardsPerRow);
    });
  }, [color, rowCount]);

  const loadCardImages = async (cardNames) => {
    const cardDataPromises = cardNames.map((card) =>
      axios.get(`https://api.scryfall.com/cards/named?exact=${encodeURIComponent(card.name)}`)
        .then(res => ({
          name: card.name,
          imageUrl: res.data.image_uris?.large
        }))
        .catch(error => {
          console.error(`Error fetching data for card: ${card.name}`, error);
          return { name: card.name, imageUrl: null}; // Handle the error gracefully
        })
    );

    const newCardData = await Promise.all(cardDataPromises);
    setCards(prevCards => [...prevCards, ...newCardData]);
    setLoading(false);
  };

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100) {
      if (cards.length < totalCardsCount && !loading) {
        setRowCount(prev => prev + 3); // Load more rows
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [cards.length, totalCardsCount, loading]);

  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <button onClick={() => navigate('/')} className="back-button">Back to Color Selection</button>
      <div className="card-list">
        {cards.map((card, index) => (
          <Card key={index} card={card} />
        ))}
      </div>
      {loading && <div>Loading more cards...</div>}
      {!loading && cards.length === totalCardsCount && <div>No more cards to load.</div>}
    </div>
  );
}

export default CardList;
