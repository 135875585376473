// ColorSelection.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ColorSelection.css'; // Make sure you have this CSS file

const colorSets = [
  { name: 'Colorless', code: 'Colorless', imageUrl: 'https://cards.scryfall.io/large/front/8/d/8d479a83-16f7-425c-9873-b900f39ed620.jpg?1690006854' },
  { name: 'White', code: 'White_W', imageUrl: 'https://cards.scryfall.io/large/front/4/7/4716a32c-91a6-470a-a686-d5eb3d27f46e.jpg?1699045084' },
  { name: 'Red', code: 'Red_R', imageUrl: 'https://cards.scryfall.io/large/front/e/8/e82fc4bc-ac81-4ad6-8b33-781d047f60d5.jpg?1699045101' },
  { name: 'Blue', code: 'Blue_U', imageUrl: 'https://cards.scryfall.io/large/front/2/3/23e3ed2c-342b-44aa-8e3e-0c53602397c3.jpg?1699045089' },
  { name: 'Black', code: 'Black_B', imageUrl: 'https://cards.scryfall.io/large/front/4/f/4f893107-84b0-4e3f-b1f5-b04632f192c5.jpg?1699045094' },
  { name: 'Green', code: 'Green_G', imageUrl: 'https://cards.scryfall.io/large/front/f/9/f954219a-fd4b-4fb1-945f-7950efc1d975.jpg?1699045105' },
  { name: 'White Blue', code: 'WhiteBlue_WU', imageUrl: 'https://cards.scryfall.io/large/front/e/f/efd35cb4-862d-4699-a197-b744989b3ceb.jpg?1562943174' },
  { name: 'Blue Black', code: 'BlueBlack_UB', imageUrl: 'https://cards.scryfall.io/large/front/2/6/26cee543-6eab-494e-a803-33a5d48d7d74.jpg?1562902883' },
  { name: 'Black Red', code: 'BlackRed_BR', imageUrl: 'https://cards.scryfall.io/large/front/7/3/73403d04-fe97-4830-8b80-16dd1a1a6cc1.jpg?1562918068' },
  { name: 'Red Green', code: 'RedGreen_RG', imageUrl: 'https://cards.scryfall.io/large/front/0/c/0c2c39fc-b564-4ab5-833c-ff029760b7a7.jpg?1562897467' },
  { name: 'Green White', code: 'GreenWhite_GW', imageUrl: 'https://cards.scryfall.io/large/front/b/0/b0d161fc-4a2a-4f1d-82b4-a746552552df.jpg?1562930574' },
  { name: 'White Black', code: 'WhiteBlack_WB', imageUrl: 'https://cards.scryfall.io/large/front/9/d/9d471e36-a3ab-4a96-ba4b-8eca921ea37a.jpg?1562926691' },
  { name: 'Red White', code: 'RedWhite_RW', imageUrl: 'https://cards.scryfall.io/large/front/b/b/bb979a96-a57d-4fb5-8ebe-0bd398272abe.jpg?1562932720' },
  { name: 'Blue Red', code: 'BlueRed_UR', imageUrl: 'https://cards.scryfall.io/large/front/2/f/2f607e7e-30c0-45e9-8f61-bf6e9fe63f2b.jpg?1562904669' },
  { name: 'Black Green', code: 'BlackGreen_BG', imageUrl: 'https://cards.scryfall.io/large/front/b/d/bd7567df-b4d8-41a8-8eac-c05afa784bfe.jpg?1562933075' },
  { name: 'Green Blue', code: 'GreenBlue_GU', imageUrl: 'https://cards.scryfall.io/large/front/4/7/47033ba4-8f26-4a6b-97bd-5b366327325e.jpg?1632873386' },
  { name: 'White Black Green', code: 'WhiteBlackGreen_WBG', imageUrl: 'https://cards.scryfall.io/large/front/2/b/2b74bb81-fb9a-40e5-a941-e517430b52f5.jpg?1636491374' },
  { name: 'Green Blue Red', code: 'GreenBlueRed_GUR', imageUrl: 'https://cards.scryfall.io/large/front/a/2/a249b1f4-2b22-4b67-a207-e0c4ae95d2e1.jpg?1601241399' },
  { name: 'Blue Red White', code: 'BlueRedWhite_URW', imageUrl: 'https://cards.scryfall.io/large/front/0/2/02138fbb-3962-4348-8d31-faaefba0b8b2.jpg?1591228666' },
  { name: 'Red White Black', code: 'RedWhiteBlack_RWB', imageUrl: 'https://cards.scryfall.io/large/front/7/4/748e6a61-9c1f-4225-9f04-e54002f63ac3.jpg?1591228681' },
  { name: 'Black Green Blue', code: 'BlackGreenBlue_BGU', imageUrl: 'https://cards.scryfall.io/large/front/c/c/cc520518-2063-4b57-a0d4-10cf62a7175e.jpg?1591228738' },
  { name: 'Red Green White', code: 'RedGreenWhite_RGW', imageUrl: 'https://cards.scryfall.io/large/front/2/6/26d40e03-6de4-4373-9fbf-04c1dd79e995.jpg?1664414440' },
  { name: 'White Blue Black', code: 'WhiteBlueBlack_WUB', imageUrl: 'https://cards.scryfall.io/large/front/a/2/a2c56479-4bee-4edb-80d7-4af010b7c793.jpg?1664414518' },
  { name: 'Green White Blue', code: 'GreenWhiteBlue_GWU', imageUrl: 'https://cards.scryfall.io/large/front/7/3/7363f1fb-9af3-4212-921f-d59533faf0e5.jpg?1664414585' },
  { name: 'Blue Black Red', code: 'BlueBlackRed_UBR', imageUrl: 'https://cards.scryfall.io/large/front/5/4/54f449ff-4025-465e-9ec5-a5cf42c4c9d3.jpg?1664414651' },
  { name: 'Black Red Green', code: 'BlackRedGreen_BRG', imageUrl: 'https://cards.scryfall.io/large/front/7/5/75fdce80-e338-4a50-bdc6-786511feaeef.jpg?1664414670' },
  { name: 'Blue Black Red Green', code: 'BlueBlackRedGreen_UBRG', imageUrl: 'https://cards.scryfall.io/large/front/a/4/a4f00d2b-d616-455a-bf9b-5bd82b690121.jpg?1673305536' },
  { name: 'White Black Red Green', code: 'WhiteBlackRedGreen_WBRG', imageUrl: 'https://cards.scryfall.io/large/front/1/5/15b4ee44-28c4-4a39-9c06-aca43787954f.jpg?1593272643' },
  { name: 'White Blue Red Green', code: 'WhiteBlueRedGreen_WURG', imageUrl: 'https://cards.scryfall.io/large/front/d/2/d29bb432-1ca6-4305-b6d3-d7afe06c6c69.jpg?1593272693' },
  { name: 'White Blue Black Green', code: 'WhiteBlueBlackGreen_WUBG', imageUrl: 'https://cards.scryfall.io/normal/front/1/8/185d56ab-b7f2-4eb2-9540-db8f2dc9b436.jpg?1593272845' },
  { name: 'White Blue Black Red', code: 'WhiteBlueBlackRed_WUBR', imageUrl: 'https://cards.scryfall.io/large/front/0/2/0247d76c-dee4-4d1b-9c25-faa56171541e.jpg?1593272858' },
  { name: 'White Blue Black Red Green', code: 'WhiteBlueBlackRedGreen_WUBRG', imageUrl: 'https://cards.scryfall.io/normal/front/a/8/a8a5d0ba-bcb1-41db-80dd-ad22b8408105.jpg?1561968078' }
];

const ColorSelection = () => {
  const navigate = useNavigate();

  const navigateToCards = (colorCode) => {
    navigate(`/cards/${colorCode}`);
  };

  return (
    <div className="color-selection">
      {colorSets.map((colorSet) => (
        <div
          key={colorSet.code}
          className="color-choice"
          onClick={() => navigateToCards(colorSet.code)}
          style={{ backgroundImage: `url(${colorSet.imageUrl})` }}
        >
          <span>{colorSet.name}</span>
        </div>
      ))}
    </div>
  );
};

export default ColorSelection;
